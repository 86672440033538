.project {
  margin:84px 0;
}

.project h2 {
  font-family:"Roboto", sans-serif;
  font-size:2.5em;
  font-weight:600;
  letter-spacing:0.005em;
  color:#333;
  display:inline-block;
  margin:0 8px 0 0;
}

.project .detail {
  max-width:888px;
  /* margin:12px 0 0; */
}

.project span.bridge {
  font-size:14px;
  display:inline-block;
  margin:0 2px 0 0;
  font-family:"Quicksand", sans-serif;
  color:#aaa;
}

.project span.subtitle {
  font-size:15px;
  display:inline-block;
  font-family:"Quicksand", sans-serif;
  font-weight:500;
  text-transform:uppercase;
  color:#999;
}

.project .link a {
  font-size:16px;
  font-weight:500;
  font-family:"Quicksand", sans-serif;
}

.project .link a:hover {
  text-decoration:underline;
}

.project .description {
  margin:6px 0 24px;
}

.project .description p {
  vertical-align:top;
  display:inline-block;
  max-width:800px;
  color:#333;
  font-size:20px;
  font-family:"Roboto", sans-serif;
  font-weight:400;
  line-height:32px;
  /* padding-left:16px; */
}

.project .tags {
  /* display:inline-block; */
  /* vertical-align:top; */
  float:right;
  display:block;
  margin-left:18px;
  width:150px;
}

.project .tags span {
  font-size:0.9em;
  display:inline-block;
  margin:0 6px 6px 0;
  font-family:"Roboto", sans-serif;
  font-weight:500;
  padding:4px 8px;
}

.project .tags .role {
  background:#ffffcc;
}

.golang, .javascript, .python, .ruby, .c, .reactjs, .relayjs, .mysql, .mongodb,
.elasticsearch, .unreal-engine-4, .redis, .rails, .objective-c, .php {
  background:#f1e7fe;
}

.nlp, .machine-learning, .data-science, .text-extraction, .computer-vision,
.threed, .physics, .game-development, .game-design, .accounting, .hr,
.leadership, .design, .process, .product, .insurance, .education, .math,
.gaming, .drones, .imagery {
  background:#c8f7c5;
}

.steam, .ps4,
.graphql, .dev-ops, .kubernetes, .gcp, .aws, .digital-ocean, .docker, .networking {
  background:#e4f1fe;
}

.project .slides {
  float:left;
  display:block;
  width:720px;
  /* display:inline-block; */
  /* vertical-align:top; */
  position:relative;
}

.project .slides .frame {
  width:720px;
}

.video-slide .video-wrapper,
.project .slides .picture-frame {
  width:720px;
  height:400px;
  overflow:hidden;
  display:block;
  position:relative;
  box-sizing:border-box;
  border:1px solid #eee;
  background:#000;
}

.project .slides img {
  width:720px;
}

.project .slides .gallery-thumbnail {
  overflow:hidden;
}

.project .slides .gallery-thumbnail img {
  width:100px;
}

.project .image-gallery-slides {
  width:720px;
  height:640px;
}

.project .image-gallery-slide {
}

.project .image-gallery-image {
  width:720px;
  height:400px;
}

.project .image-gallery-slide .image-gallery-description {
  height:240px;
  box-sizing:border-box;
  padding:12px 24px;
  padding:12px 0;
  border:none;
  position:relative;
  display:block;
  bottom:unset;
  /* display:table; */
  width:100%;
  background:rgba(255, 249, 222, 0.7);
  background:#333;
  background:#fff;
}

.project .image-gallery-slide .image-gallery-description .content {
  /* display:table-cell; */
  vertical-align:middle;
}

.project .image-gallery-slide .image-gallery-description h6 {
  font-size:15px;
  line-height:17px;
  margin:0;
  font-family:"Roboto", sans-serif;
  font-weight:700;
  text-transform:uppercase;
  /* color:#fff; */
  /* background:#fafafa; */
  /* border:1px solid #555; */
  color:#333;
  /* border-bottom:1px solid #333; */
  /* border-bottom:1px solid #0099ff; */
  display:inline-block;
  /* padding:5px 12px; */
  padding:0 0 2px 0;
  margin:8px 0 4px;
  line-height:24px;
}

.project .image-gallery-slide .image-gallery-description p {
  font-size:16px;
  line-height:22px;
  font-family:"Roboto", sans-serif;
  margin:4px 0 0;
  color:#777;
  /* color:#eee; */
  font-size:20px;
  font-family:"Roboto", sans-serif;
  font-weight:400;
  line-height:32px;
  color:#333;
}

.project .image-gallery-slide .image-gallery-description p i {
  font-style:normal;
  font-family:monospace;
  font-size:1.1em;
  letter-spacing:-0.04em;
}


.video-slide {
  position:relative;
  cursor:pointer;
}

.video-slide .video-wrapper {
  overflow:hidden;
  height:400px;
  display:block;
  position:relative;
  box-sizing:border-box;
  border:1px solid #eee;
}

.video-slide video {
  width:100%;
}

.hover-play {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#000;
  opacity:0;
  transition:opacity 0.5s linear;
  z-index:1;
}

.video-slide:hover .hover-play {
  opacity:0.6;
}

.video-slide.playing .hover-play {
  opacity:0;
  transition:opacity 0.1s linear;
}

.hover-play .play-button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 74px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 37px 0 37px 60px;
  margin:160px auto 0;
  cursor:pointer;
}

.hover-play .text {
  color: #fff;
  margin: 24px 0 0 3px;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
}

.project .image-gallery-slide-wrapper .image-gallery-right-nav,
.project .image-gallery-slide-wrapper .image-gallery-left-nav {
  height:400px;
  top:200px;
  opacity:0;
  transition:opacity 0.5s ease, background-color 0.5s ease;
}

.project .image-gallery-slide-wrapper:hover .image-gallery-right-nav,
.project .image-gallery-slide-wrapper:hover .image-gallery-left-nav {
  opacity:1;
}

.project .image-gallery-right-nav,
.project .image-gallery-left-nav {
  background:#333;
  height:calc(100% - 2px);
  width:60px;
  box-sizing:border-box;
  padding:0 12px;
  border:none;
  background-color:rgba(0,0,0,0.1);
  transition:background-color 0.5s ease;
}

.project .image-gallery-right-nav:hover,
.project .image-gallery-left-nav:hover {
  background-color:rgba(0,0,0,0.5);
}

.project .image-gallery-left-nav::before,
.project .image-gallery-right-nav::before {
  opacity:0.6;
  text-shadow:none;
  font-size:48px;
  content: "\F3D2";
  color:#fafafa;
  transition:opacity 0.5s ease;
}

.project .image-gallery-right-nav::before {
  content:"\F3D3";
}

.project .image-gallery-left-nav:hover::before,
.project .image-gallery-right-nav:hover::before {
  opacity:1;
  color:#fff;
}
