@media (max-width:1480px)  { /* narrow screens */
  .project .slides {
    display:block;
    float:none;
    clear:both;
  }

  .project .tags {
    display:block;
    float:none;
    clear:both;
    width:500px;
    margin:-24px 0 24px 0;
  }
}

@media (max-width:1200px)  { /* big phones */
  .hero .title {
    top:5%;
    width:80%;
  }

  .summary {
    height:660px;
  }

  .summary .content-box {
    width:80%;
  }

  .work .header {
    width:80%;
  }

  .work .projects {
    width:80%;
  }

  .project span.subtitle {
    font-size:18px;
    display:block;
    font-family:"Quicksand", sans-serif;
    font-weight:500;
    color:#888;
    margin:0 0 2px;
    text-transform:uppercase;
    font-size:12px;
  }

  .project .description p {
    margin:1em 0;
  }

  .project .slides {
    width:100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width:100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width:100%;
    height:40%;
  }

  .project .image-gallery-slides {
    width:100%;
    height:60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top:80px;
    height:60px;
    width:60px;
    text-align:center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right:-32px;
    padding:0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left:-32px;
    padding:0 8px 0 0;
  }
}

@media (max-width:480px)  { /* big phones */
  .hero .title {
    top:5%;
    width:80%;
  }

  .hero .title h2 {
    font-size:1.5em;
  }

  .hero .title h2 a {
    font-size:24px;
    margin:0 0 0 12px;
  }

  .hero .title h1 {
    font-size:1.8em;
  }

  .summary {
    height:400px;
  }

  .summary .content-box {
    padding:24px;
    width:80%;
    margin:30px auto;
  }

  .summary .content-box p:first-child {
    font-weight:500;
    font-size:18px;
    line-height:22px;
    margin-bottom:0.8em;
  }

  .summary .content-box p {
    font-weight:300;
    font-size:15px;
    line-height:21px;
  }

  .summary .content-box p:last-child {
    margin-bottom:0;
  }

  .work {
    padding-top:36px;
    margin-top:0;
  }

  .work .header {
    width:80%;
    margin:18px auto 0;
  }

  .work .header h1 {
    font-size:1.5em;
    display: block;
    padding:4px 16px;
    color: #36d7b7;
    background:transparent;
    padding:0;
    border-bottom:1px solid #36d7b7;
  }

  .work .projects {
    width:80%;
    margin:60px auto 0;
  }

  .project {
    margin:48px 0;
  }

  .project h2 {
    font-size:1.8em;
  }

  .project span.subtitle {
    font-size:18px;
    display:block;
    font-family:"Quicksand", sans-serif;
    font-weight:500;
    color:#888;
    margin:0 0 2px;
    text-transform:uppercase;
    font-size:12px;
  }

  .project .description p {
    margin:1em 0;
  }

  .project .slides {
    width:100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width:100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width:100%;
    height:40%;
  }

  .project .image-gallery-slides {
    width:100%;
    height:60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top:80px;
    height:60px;
    width:60px;
    text-align:center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right:-32px;
    padding:0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left:-32px;
    padding:0 8px 0 0;
  }

  .project .image-gallery-slide .image-gallery-description h6 {
    font-size:12px;
    text-transform:uppercase;
  }

  .project .image-gallery-slide .image-gallery-description p {
    font-size:16px;
    line-height:24px;
  }

  .project .slides {
    display:block;
    float:none;
    clear:both;
  }

  .project .tags {
    display:block;
    float:none;
    clear:both;
    width:100%;
    margin:-24px 0 24px 0;
  }

  .contact {
  }

  .contact form {
    width:80%;
    margin:45px auto 80px;
  }

  .contact form button {
    width:100%;
  }
}
