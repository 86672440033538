.work {
  margin-top:84px;
}

.work .line {
  width:100%;
  margin-top:-38px;
}

.work .header {
  width:60%;
  margin:0 auto;
}

.work .header h1 {
  font-size:4em;
  font-family:"Roboto", sans-serif;
  /* background: #e5fff9; */
  background:transparent;
  display: inline-block;
  /* padding: 8px 24px; */
  padding:36px 0 0;
  color: #36d7b7;
  border-bottom:1px solid #36d7b7;
  border-radius: 2px;
  margin:0;
}

.work nav {
}

.work .projects {
  width:60%;
  margin:124px auto 0;
}
