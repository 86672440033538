.summary {
  height:600px;
  width:100%;
  position:relative;
  overflow:hidden;
  background:linear-gradient(-170deg, #f1e7fe 55%, #fff 55%);
}

.stripe {
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background:linear-gradient(160deg, #36d7b7 15%, #fff 94%);
}

.summary .content-box {
  position:relative;
  background:#fff;
  color:#333;
  border-radius:2px;
  padding:56px;
  width:60%;
  box-sizing:border-box;
  margin:80px auto;
  font-size:28px;
  line-height:1.33em;
  font-family:'Roboto', sans-serif;
}

.summary .content-box p:first-child {
  font-weight:500;
  font-size:32px;
  margin-bottom:0.8em;
}

.summary .content-box p {
  font-weight:300;
  font-size:26px;
}

.summary .content-box p:last-child {
  margin-bottom:0;
}

.summary .content-box p:last-child em {
  font-style:normal;
  font-family:"Lucida Console", monospace;
  font-family:"SFMono-Regular","Consolas","Liberation Mono","Menlo",Courier,monospace;
  display:inline-block;
  padding:0px 14px;
  text-transform:uppercase;
  font-size:0.7em;
  border-radius:2px;
  margin:0 6px 6px 0;
  /* vertical-align:middle; */
}

.summary .content-box p em.go {
  background:#e4f1fe;
  border:1px solid #d8ebff;
  color:#333;
}

.summary .content-box p em.react {
  background:#fff9de;
  border:1px solid #fff68f;
  color:#333;
}

.summary .content-box p em.sql {
  background:#f1e7fe;
  border:1px solid #ecddff;
  color:#333;
}

.summary .content-box p em.k8s {
  background:#c8f7c5;
  border:1px solid #b2efae;
  color:#333;
}

.summary .content-box p em.ue4 {
  background:#fde3a7;
  border:1px solid #ffd77c;
  color:#333;
}
