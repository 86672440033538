.hero::before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  position: relative;
  background-image: url('./img/hero2x.png');
  background-image: url('./img/woodman-action-dark.png');
  background-size: cover;
  background-position: center center;
  box-sizing: border-box;
  border-bottom: 2px solid #36d7b7;
}

.hero .title {
  width: 60%;
  top: 20%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.hero .title h1 {
  color: #fff;
  font-size: 4em;
  line-height: 1.1em;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.hero .title h2 {
  font-size: 2.5em;
  font-weight: 600;
  color: #fff68f;
}

.hero .title h2 span {
  display: inline-block;
  vertical-align: middle;
}

.hero .title a {
  display: inline-block;
  width: 195px;
  /* float:left; */
  box-sizing: border-box;
  padding: 8px 30px;
  border-radius: 2px;
  margin: 0.5em 0 0;
  font-size: 1.25em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background: #36d7b7;
  color: #fff;
  transition: 0.5s background linear;
  cursor: pointer;
}

.hero .title a:hover {
  background: #1ba39c;
}

.hero .title a.contact-me {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
}

.hero .title a.contact-me:hover {
  opacity: 1;
}

.hero .title a.social {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
}

.hero .title a.social:hover {
  opacity: 1;
}