@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600|Roboto:400,500,700|Quicksand:400,500);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero::before {
  background: rgba(0, 0, 0, 0.3);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  position: relative;
  background-image: url(/static/media/hero2x.3f4ba936.png);
  background-image: url(/static/media/woodman-action-dark.a7d5cd8b.png);
  background-size: cover;
  background-position: center center;
  box-sizing: border-box;
  border-bottom: 2px solid #36d7b7;
}

.hero .title {
  width: 60%;
  top: 20%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.hero .title h1 {
  color: #fff;
  font-size: 4em;
  line-height: 1.1em;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.hero .title h2 {
  font-size: 2.5em;
  font-weight: 600;
  color: #fff68f;
}

.hero .title h2 span {
  display: inline-block;
  vertical-align: middle;
}

.hero .title a {
  display: inline-block;
  width: 195px;
  /* float:left; */
  box-sizing: border-box;
  padding: 8px 30px;
  border-radius: 2px;
  margin: 0.5em 0 0;
  font-size: 1.25em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  background: #36d7b7;
  color: #fff;
  -webkit-transition: 0.5s background linear;
  transition: 0.5s background linear;
  cursor: pointer;
}

.hero .title a:hover {
  background: #1ba39c;
}

.hero .title a.contact-me {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
}

.hero .title a.contact-me:hover {
  opacity: 1;
}

.hero .title a.social {
  background: transparent;
  width: 30px;
  font-size: 28px;
  padding: 0;
  margin: 0 0 3px 12px;
  opacity: 0.7;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  display: inline-block;
  vertical-align: middle;
}

.hero .title a.social:hover {
  opacity: 1;
}
.summary {
  height:600px;
  width:100%;
  position:relative;
  overflow:hidden;
  background:-webkit-linear-gradient(260deg, #f1e7fe 55%, #fff 55%);
  background:linear-gradient(-170deg, #f1e7fe 55%, #fff 55%);
}

.stripe {
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
  -webkit-transform: skewY(-12deg);
  transform: skewY(-12deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background:-webkit-linear-gradient(290deg, #36d7b7 15%, #fff 94%);
  background:linear-gradient(160deg, #36d7b7 15%, #fff 94%);
}

.summary .content-box {
  position:relative;
  background:#fff;
  color:#333;
  border-radius:2px;
  padding:56px;
  width:60%;
  box-sizing:border-box;
  margin:80px auto;
  font-size:28px;
  line-height:1.33em;
  font-family:'Roboto', sans-serif;
}

.summary .content-box p:first-child {
  font-weight:500;
  font-size:32px;
  margin-bottom:0.8em;
}

.summary .content-box p {
  font-weight:300;
  font-size:26px;
}

.summary .content-box p:last-child {
  margin-bottom:0;
}

.summary .content-box p:last-child em {
  font-style:normal;
  font-family:"Lucida Console", monospace;
  font-family:"SFMono-Regular","Consolas","Liberation Mono","Menlo",Courier,monospace;
  display:inline-block;
  padding:0px 14px;
  text-transform:uppercase;
  font-size:0.7em;
  border-radius:2px;
  margin:0 6px 6px 0;
  /* vertical-align:middle; */
}

.summary .content-box p em.go {
  background:#e4f1fe;
  border:1px solid #d8ebff;
  color:#333;
}

.summary .content-box p em.react {
  background:#fff9de;
  border:1px solid #fff68f;
  color:#333;
}

.summary .content-box p em.sql {
  background:#f1e7fe;
  border:1px solid #ecddff;
  color:#333;
}

.summary .content-box p em.k8s {
  background:#c8f7c5;
  border:1px solid #b2efae;
  color:#333;
}

.summary .content-box p em.ue4 {
  background:#fde3a7;
  border:1px solid #ffd77c;
  color:#333;
}

.project {
  margin:84px 0;
}

.project h2 {
  font-family:"Roboto", sans-serif;
  font-size:2.5em;
  font-weight:600;
  letter-spacing:0.005em;
  color:#333;
  display:inline-block;
  margin:0 8px 0 0;
}

.project .detail {
  max-width:888px;
  /* margin:12px 0 0; */
}

.project span.bridge {
  font-size:14px;
  display:inline-block;
  margin:0 2px 0 0;
  font-family:"Quicksand", sans-serif;
  color:#aaa;
}

.project span.subtitle {
  font-size:15px;
  display:inline-block;
  font-family:"Quicksand", sans-serif;
  font-weight:500;
  text-transform:uppercase;
  color:#999;
}

.project .link a {
  font-size:16px;
  font-weight:500;
  font-family:"Quicksand", sans-serif;
}

.project .link a:hover {
  text-decoration:underline;
}

.project .description {
  margin:6px 0 24px;
}

.project .description p {
  vertical-align:top;
  display:inline-block;
  max-width:800px;
  color:#333;
  font-size:20px;
  font-family:"Roboto", sans-serif;
  font-weight:400;
  line-height:32px;
  /* padding-left:16px; */
}

.project .tags {
  /* display:inline-block; */
  /* vertical-align:top; */
  float:right;
  display:block;
  margin-left:18px;
  width:150px;
}

.project .tags span {
  font-size:0.9em;
  display:inline-block;
  margin:0 6px 6px 0;
  font-family:"Roboto", sans-serif;
  font-weight:500;
  padding:4px 8px;
}

.project .tags .role {
  background:#ffffcc;
}

.golang, .javascript, .python, .ruby, .c, .reactjs, .relayjs, .mysql, .mongodb,
.elasticsearch, .unreal-engine-4, .redis, .rails, .objective-c, .php {
  background:#f1e7fe;
}

.nlp, .machine-learning, .data-science, .text-extraction, .computer-vision,
.threed, .physics, .game-development, .game-design, .accounting, .hr,
.leadership, .design, .process, .product, .insurance, .education, .math,
.gaming, .drones, .imagery {
  background:#c8f7c5;
}

.steam, .ps4,
.graphql, .dev-ops, .kubernetes, .gcp, .aws, .digital-ocean, .docker, .networking {
  background:#e4f1fe;
}

.project .slides {
  float:left;
  display:block;
  width:720px;
  /* display:inline-block; */
  /* vertical-align:top; */
  position:relative;
}

.project .slides .frame {
  width:720px;
}

.video-slide .video-wrapper,
.project .slides .picture-frame {
  width:720px;
  height:400px;
  overflow:hidden;
  display:block;
  position:relative;
  box-sizing:border-box;
  border:1px solid #eee;
  background:#000;
}

.project .slides img {
  width:720px;
}

.project .slides .gallery-thumbnail {
  overflow:hidden;
}

.project .slides .gallery-thumbnail img {
  width:100px;
}

.project .image-gallery-slides {
  width:720px;
  height:640px;
}

.project .image-gallery-slide {
}

.project .image-gallery-image {
  width:720px;
  height:400px;
}

.project .image-gallery-slide .image-gallery-description {
  height:240px;
  box-sizing:border-box;
  padding:12px 24px;
  padding:12px 0;
  border:none;
  position:relative;
  display:block;
  bottom:unset;
  /* display:table; */
  width:100%;
  background:rgba(255, 249, 222, 0.7);
  background:#333;
  background:#fff;
}

.project .image-gallery-slide .image-gallery-description .content {
  /* display:table-cell; */
  vertical-align:middle;
}

.project .image-gallery-slide .image-gallery-description h6 {
  font-size:15px;
  line-height:17px;
  margin:0;
  font-family:"Roboto", sans-serif;
  font-weight:700;
  text-transform:uppercase;
  /* color:#fff; */
  /* background:#fafafa; */
  /* border:1px solid #555; */
  color:#333;
  /* border-bottom:1px solid #333; */
  /* border-bottom:1px solid #0099ff; */
  display:inline-block;
  /* padding:5px 12px; */
  padding:0 0 2px 0;
  margin:8px 0 4px;
  line-height:24px;
}

.project .image-gallery-slide .image-gallery-description p {
  font-size:16px;
  line-height:22px;
  font-family:"Roboto", sans-serif;
  margin:4px 0 0;
  color:#777;
  /* color:#eee; */
  font-size:20px;
  font-family:"Roboto", sans-serif;
  font-weight:400;
  line-height:32px;
  color:#333;
}

.project .image-gallery-slide .image-gallery-description p i {
  font-style:normal;
  font-family:monospace;
  font-size:1.1em;
  letter-spacing:-0.04em;
}


.video-slide {
  position:relative;
  cursor:pointer;
}

.video-slide .video-wrapper {
  overflow:hidden;
  height:400px;
  display:block;
  position:relative;
  box-sizing:border-box;
  border:1px solid #eee;
}

.video-slide video {
  width:100%;
}

.hover-play {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#000;
  opacity:0;
  -webkit-transition:opacity 0.5s linear;
  transition:opacity 0.5s linear;
  z-index:1;
}

.video-slide:hover .hover-play {
  opacity:0.6;
}

.video-slide.playing .hover-play {
  opacity:0;
  -webkit-transition:opacity 0.1s linear;
  transition:opacity 0.1s linear;
}

.hover-play .play-button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 74px;
  border-color: transparent transparent transparent #fff;
  -webkit-transition: 100ms all ease;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 37px 0 37px 60px;
  margin:160px auto 0;
  cursor:pointer;
}

.hover-play .text {
  color: #fff;
  margin: 24px 0 0 3px;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
}

.project .image-gallery-slide-wrapper .image-gallery-right-nav,
.project .image-gallery-slide-wrapper .image-gallery-left-nav {
  height:400px;
  top:200px;
  opacity:0;
  -webkit-transition:opacity 0.5s ease, background-color 0.5s ease;
  transition:opacity 0.5s ease, background-color 0.5s ease;
}

.project .image-gallery-slide-wrapper:hover .image-gallery-right-nav,
.project .image-gallery-slide-wrapper:hover .image-gallery-left-nav {
  opacity:1;
}

.project .image-gallery-right-nav,
.project .image-gallery-left-nav {
  background:#333;
  height:calc(100% - 2px);
  width:60px;
  box-sizing:border-box;
  padding:0 12px;
  border:none;
  background-color:rgba(0,0,0,0.1);
  -webkit-transition:background-color 0.5s ease;
  transition:background-color 0.5s ease;
}

.project .image-gallery-right-nav:hover,
.project .image-gallery-left-nav:hover {
  background-color:rgba(0,0,0,0.5);
}

.project .image-gallery-left-nav::before,
.project .image-gallery-right-nav::before {
  opacity:0.6;
  text-shadow:none;
  font-size:48px;
  content: "\F3D2";
  color:#fafafa;
  -webkit-transition:opacity 0.5s ease;
  transition:opacity 0.5s ease;
}

.project .image-gallery-right-nav::before {
  content:"\F3D3";
}

.project .image-gallery-left-nav:hover::before,
.project .image-gallery-right-nav:hover::before {
  opacity:1;
  color:#fff;
}

.work {
  margin-top:84px;
}

.work .line {
  width:100%;
  margin-top:-38px;
}

.work .header {
  width:60%;
  margin:0 auto;
}

.work .header h1 {
  font-size:4em;
  font-family:"Roboto", sans-serif;
  /* background: #e5fff9; */
  background:transparent;
  display: inline-block;
  /* padding: 8px 24px; */
  padding:36px 0 0;
  color: #36d7b7;
  border-bottom:1px solid #36d7b7;
  border-radius: 2px;
  margin:0;
}

.work nav {
}

.work .projects {
  width:60%;
  margin:124px auto 0;
}

.contact {
  background:#2e3131;
  padding:1px 0;
}

.contact form {
  width:60%;
  margin:95px auto 127px;
}

.contact form h1 {
  color:#fff;
  font-size:36px;
}

.contact form label {
  color:#ddd;
  display:block;
  margin:0 0 4px;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

.app {
}

.app header {
  position:fixed;
  top:0;
  right:0;
  z-index:9999;
}

.app header a {
  font-family:'Open Sans', sans-serif;
  color:#fff;
  display:inline-block;
  padding:16px;
  vertical-align:middle;
  text-transform:uppercase;
  font-size:0.8em;
  letter-spacing:0.05em;
  font-weight:600;
  -webkit-transition:0.5s opacity linear;
  transition:0.5s opacity linear;
  opacity:0.5;
  cursor:pointer;
}

.app header a:hover {
  opacity:1;
}

.app header a:last-child {
  margin-right:16px;
}

@media (max-width:1480px)  { /* narrow screens */
  .project .slides {
    display:block;
    float:none;
    clear:both;
  }

  .project .tags {
    display:block;
    float:none;
    clear:both;
    width:500px;
    margin:-24px 0 24px 0;
  }
}

@media (max-width:1200px)  { /* big phones */
  .hero .title {
    top:5%;
    width:80%;
  }

  .summary {
    height:660px;
  }

  .summary .content-box {
    width:80%;
  }

  .work .header {
    width:80%;
  }

  .work .projects {
    width:80%;
  }

  .project span.subtitle {
    font-size:18px;
    display:block;
    font-family:"Quicksand", sans-serif;
    font-weight:500;
    color:#888;
    margin:0 0 2px;
    text-transform:uppercase;
    font-size:12px;
  }

  .project .description p {
    margin:1em 0;
  }

  .project .slides {
    width:100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width:100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width:100%;
    height:40%;
  }

  .project .image-gallery-slides {
    width:100%;
    height:60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top:80px;
    height:60px;
    width:60px;
    text-align:center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right:-32px;
    padding:0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left:-32px;
    padding:0 8px 0 0;
  }
}

@media (max-width:480px)  { /* big phones */
  .hero .title {
    top:5%;
    width:80%;
  }

  .hero .title h2 {
    font-size:1.5em;
  }

  .hero .title h2 a {
    font-size:24px;
    margin:0 0 0 12px;
  }

  .hero .title h1 {
    font-size:1.8em;
  }

  .summary {
    height:400px;
  }

  .summary .content-box {
    padding:24px;
    width:80%;
    margin:30px auto;
  }

  .summary .content-box p:first-child {
    font-weight:500;
    font-size:18px;
    line-height:22px;
    margin-bottom:0.8em;
  }

  .summary .content-box p {
    font-weight:300;
    font-size:15px;
    line-height:21px;
  }

  .summary .content-box p:last-child {
    margin-bottom:0;
  }

  .work {
    padding-top:36px;
    margin-top:0;
  }

  .work .header {
    width:80%;
    margin:18px auto 0;
  }

  .work .header h1 {
    font-size:1.5em;
    display: block;
    padding:4px 16px;
    color: #36d7b7;
    background:transparent;
    padding:0;
    border-bottom:1px solid #36d7b7;
  }

  .work .projects {
    width:80%;
    margin:60px auto 0;
  }

  .project {
    margin:48px 0;
  }

  .project h2 {
    font-size:1.8em;
  }

  .project span.subtitle {
    font-size:18px;
    display:block;
    font-family:"Quicksand", sans-serif;
    font-weight:500;
    color:#888;
    margin:0 0 2px;
    text-transform:uppercase;
    font-size:12px;
  }

  .project .description p {
    margin:1em 0;
  }

  .project .slides {
    width:100%;
  }

  .project .slides,
  .project .slides .frame,
  .project .slides img {
    width:100%;
  }

  .video-slide .video-wrapper,
  .project .image-gallery-image,
  .project .slides .picture-frame {
    width:100%;
    height:40%;
  }

  .project .image-gallery-slides {
    width:100%;
    height:60%;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav,
  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    top:80px;
    height:60px;
    width:60px;
    text-align:center;
  }

  .project .image-gallery-slide-wrapper .image-gallery-right-nav {
    margin-right:-32px;
    padding:0 0 0 8px;
  }

  .project .image-gallery-slide-wrapper .image-gallery-left-nav {
    margin-left:-32px;
    padding:0 8px 0 0;
  }

  .project .image-gallery-slide .image-gallery-description h6 {
    font-size:12px;
    text-transform:uppercase;
  }

  .project .image-gallery-slide .image-gallery-description p {
    font-size:16px;
    line-height:24px;
  }

  .project .slides {
    display:block;
    float:none;
    clear:both;
  }

  .project .tags {
    display:block;
    float:none;
    clear:both;
    width:100%;
    margin:-24px 0 24px 0;
  }

  .contact {
  }

  .contact form {
    width:80%;
    margin:45px auto 80px;
  }

  .contact form button {
    width:100%;
  }
}

