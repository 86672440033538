.contact {
  background:#2e3131;
  padding:1px 0;
}

.contact form {
  width:60%;
  margin:95px auto 127px;
}

.contact form h1 {
  color:#fff;
  font-size:36px;
}

.contact form label {
  color:#ddd;
  display:block;
  margin:0 0 4px;
}
